<template>
  <div class="mdc-menu-surface--anchor">
    <div class="cursor-pointer" @click="mdcMenu.open = !mdcMenu.open">
      <slot name="trigger" />
    </div>
    <div
      ref="menu"
      class="mdc-menu mdc-menu-surface mt-10"
      @click="mdcMenu.open = false"
    >
      <div
        class="mdc-list max-h-screen"
        role="menu"
        aria-hidden="true"
        aria-orientation="vertical"
      >
        <slot name="list" />
      </div>
    </div>
  </div>
</template>

<script>
import { MDCMenu } from '@material/menu';

export default {
  data: () => ({
    mdcMenu: null,
  }),

  mounted() {
    this.mdcMenu = new MDCMenu(this.$refs.menu);
  },

  beforeDestroy() {
    this.mdcMenu.destroy();
  },
};
</script>
